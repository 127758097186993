import loadjs from 'loadjs';

window.VOLYLOADER = {
  date: '__buildDate__',
  version: '__packageVersion__',
  build: '__buildVersion__'
};

const getConfig = () => {
  return Object.assign({ baseurl: 'ui.voly.co.uk', deployment: 'production' }, window.VOLY_UI_CONFIG);
};

const fireEvent = (name, data) => {
  window.dispatchEvent(new CustomEvent(name, { detail: data }));
};

const addMutationObserver = () => {
  // Select the node that will be observed for mutations
  const targetNode = document.body;
  // Options for the observer (which mutations to observe)
  const obsconfig = { attributes: false, childList: true, subtree: true };
  // Callback function to execute when mutations are observed
  const callback = async function (mutationsList, observer) {
    fireEvent('mutation', mutationsList);
    await loadLocalComponents();
    refreshComponents();
  };
  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback);
  // Start observing the target node for configured mutations
  observer.observe(targetNode, obsconfig);
};

const addClasses = () => {
  // add querystring params as classes on documentElement for style targetting
  const querystringarray = window.location.search.substr(1).replace(/=/g, '-').split('&');
  if (querystringarray.findIndex(s => s.substr(0, 5) === 'page-') < 0 && window.location.pathname !== '/') {
    querystringarray.push('page-none');
  }
  querystringarray.forEach(q => { window.document.documentElement.classList.add((q === '') ? 'no-querystring' : q); });
}

const reload = (config = getConfig()) => {
  // remove existing mutation event listeners
  removeMutationListeners();
  //reset load dependencies
  loadjs.reset();
  document.querySelectorAll('[data-component]').forEach(i => {
    // remove rendered components
    while (i.hasChildNodes()) {
      i.removeChild(i.firstChild);
    }
    // remove data-processed attribute (if applied)
    i.removeAttribute('data-processed');
  });
  // remove existing scripts and stylesheets for loaded components
  document.querySelectorAll(`[data-loaded]`).forEach(i => (i.remove()));
  // update css files by deployment
  document.querySelectorAll('link[href*=production],link[href*=staging],link[href*=development]').forEach(n => { n.href = n.href.replace(/\/(production|staging|development\/%7B[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}%7D|development)\//, `/${config.deployment}/`); });
  doLoad(config);
};

const loadIfRequired = (component, config = getConfig()) => {
  // skip loading if script is already defined and dev-build script is also defined
  if (!loadjs.isDefined(component) && !loadjs.isDefined(`dev-builds-${component}.js`) && !loadjs.isDefined(`dev-builds-${component}.css`)) {
    const requestVersion = (!!window.VOLYUIBUILD) ? `${window.VOLYUIBUILD.version}-${window.VOLYUIBUILD.build}` : new Date().getTime().toString();
    loadjs([`//${config.baseurl}/${config.deployment}/${component}.js?v=${requestVersion}`, `//${config.baseurl}/${config.deployment}/${component}.css?v=${requestVersion}`], component, {
      before: (path, el) => {
        el.setAttribute('data-loaded', component);
        document.head.appendChild(el);
        return false;
      }
    });
    loadjs.ready(component, {
      success: () => {
        fireEvent('componentLoaded', { component: component });
      },
      error: (e) => {
        fireEvent('componentFailed', { component: component, error: e });
      }
    });
  }
};

const refreshComponents = () => {
  document.querySelectorAll('[data-component]').forEach(i => {
    const component = String(i.dataset.component);
    //load the new versions
    loadIfRequired(component);
  });
};

const doLoad = (config = getConfig()) => {
  fireEvent('loader', window.VOLYLOADER);
  // load the build version
  document.querySelectorAll('[data-loaded=buildversion]').forEach(i => (i.remove()));
  loadjs(`//${config.baseurl}/${config.deployment}/buildversion.js?t=${new Date().getTime().toString()}`, {
    before: (path, el) => {
      el.setAttribute('data-loaded', 'buildversion');
      document.head.appendChild(el);
      return false;
    },
    success: () => {
      fireEvent('buildLoad', window.VOLYUIBUILD);
      refreshComponents();
    },
    error: () => {
      window.VOLYUIBUILD = {
        deployment: 'unknown',
        date: new Date().toISOString(),
        version: 'unknown',
        build: 'unknown',
        sourceBranch: 'unknown'
      };
      refreshComponents();
    }
  });
};

const removeMutationListeners = () => {
  console.log('If the reload is not updating a component it may use mutation observers to update the DOM. Try running this in the console before reloading the UI:');
  console.log('getEventListeners(window).mutation.forEach(l => window.removeEventListener(l.type, l.listener))');
};

let proto = 'http';
let host = 'localhost';
let port = 8090;
let timeout = 200;
let useTunnelUrl = false;

let address = '';

const FE_QUICK_DEV_SETTINGS = 'fequickdev.settings';

const devBuildLoad = async (component) => {
  const buildId = `dev-builds-${component}`;
  const devVersion = new Date().getTime().toString();

  // Skip loading if defined due to lazily loaded components depending on mutation observer
  if (!loadjs.isDefined(buildId)) {
    // Replace
    await loadjs(`${address}/${component}?t=devbuilds-${devVersion}`, buildId, {
      returnPromise: true,
      before: (path, el) => {
        el.setAttribute('data-loaded', buildId);
        document.head.appendChild(el);
        return false;
      },
    });
    loadjs.ready(buildId, {
      success: () => {
        fireEvent('componentLoaded', { component: buildId });
      },
      error: (e) => {
        fireEvent('componentFailed', { component: buildId, error: e });
      }
    });
  }
};

let localBuilds = [];
const loadLocalComponents = async (instantiables = Array.from(document.querySelectorAll('[data-component]'))) => {
  if (localBuilds.length === 0) return;
  const filtered = localBuilds.filter((item) => (
    instantiables.find((comp) => item.indexOf(String(comp.dataset.component)) > -1)
  ));

  for (const item of filtered) {
    await devBuildLoad(item);
  }
};

const setBaseUrlPortAndTimeout = () => {
  // Get local storage
  let value = window.localStorage.getItem(FE_QUICK_DEV_SETTINGS);
  const uuid = window.VOLY_UI_CONFIG.userData.UUID;

  try {
    value = JSON.parse(value || '{}');
    // If there is uuid in the settings tab, get the value. Otherwise, nullify it to skip the API fetch
    port = value[uuid] ? value[uuid].port : null;
    timeout = value[uuid] ? value[uuid].timeout : 200;
    host = value[uuid] ? value[uuid].network || 'localhost' : 'localhost';
    proto = host === 'localhost' ? 'http' : 'https';
    useTunnelUrl = value[uuid] ? value[uuid].useTunnelUrl : useTunnelUrl;
  } catch {
    port = 8090;
    timeout = 200;
    host = 'localhost';
    proto = host === 'localhost' ? 'http' : 'https';
    useTunnelUrl = false;
  }
}

const init = async (config = getConfig()) => {
  if (window.VOLY_UI_CONFIG && window.VOLY_UI_CONFIG.deployment !== 'production') {
    let abortController = null;

    setBaseUrlPortAndTimeout();

    // [06/04/2025] Remove the check for port now as when using tunnels, port is no longer needed and hence API won't be called
    if (proto && host && timeout) {
      try {
        // Abort early if cannot fetch data
        abortController = new AbortController();
        // Address for dev build environment
        address = useTunnelUrl
          ? `${proto}://${host}/dev-builds`
          : `${proto}://${host}:${port}/dev-builds`; 
        // If the timing function is not clear after some ms, abort fetch to avoid UI load delay on test2
        const id = setTimeout(() => abortController.abort(), timeout);
        const options = {
          headers: {
            'Bypass-Tunnel-Reminder': 'true',
          },
          signal: abortController.signal,
        };
        if (!useTunnelUrl) {
          delete options.headers;
        }
        const res = await fetch(address, options);
        clearTimeout(id);
    
        localBuilds = await res.json();
        await loadLocalComponents();
      } catch {}
    }
  }
  addMutationObserver();
  window.VOLY_UI_RELOAD = reload;
  addClasses();
  doLoad(config);
};

init();
